.Home {
  height: 100vh;
  width: 100%;
  letter-spacing: 0.1rem;

  &__carusel {
    height: 100vh;
    width: 100%;

    .carousel.carousel-slider,
    .slider-wrapper.axis-horizontal {
      height: 100%;
    }

    .carousel .slide img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @media (orientation: landscape) {
        height: 100vh;
      }
    }

    ul.slider {
      height: 100%;

      &.selected {
        flex: 1;
      }
    }

    li.slide:not(.selected) {
      opacity: 0;
      transition: opacity 1s;
    }
  }

  &__items {
    height: 100%;
    width: 100%;
    .slide.slide {
      background: none;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: start;

    &-video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-container {
      position: relative;

      &--landscape {
        width: (860 / 1920 * 100%);
      }
    }

    &-cover {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      filter: blur(8px);
      -webkit-filter: blur(8px);
      opacity: 0.5;

      &--image {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &--video {
        object-fit: cover;
        transform: scale(1.1);
        top: -1rem;
      }
    }

    & > *:first-child {
      background-color: transparent;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;
      max-height: 45%;
    }

    &--landscape {
      flex-direction: row;
      & > *:first-child {
        max-height: 100%;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: start;

      flex-grow: 1;

      padding: 5% 11%;
      width: 100%;
      font-family: "Open Sans", Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
      line-height: 1.3;
      overflow: auto;
      background-color: #333333;
      color: white;

      &--landscape {
        height: 100%;
        padding: 6% 8%;
        flex-grow: 0;
        width: ((1920 - 860) / 1920 * 100%);

        .Home__item-content-side {
          margin-bottom: 0;
        }
      }

      &-side {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        text-align: center;

        // & > *:first-child {
        //   flex: 1;
        // }
      }

      &__logo {
        width: 126px !important;
        height: 126px !important;
        margin: 0 auto;
        flex-grow: 0;
        flex-shrink: 0;

        path {
          fill: white;
        }
      }
    }

    &-meta {
      width: 100%;
      margin-bottom: 2em;
      @media screen and (orientation: landscape) {
        margin-bottom: 2em;
      }
    }

    &-time {
      padding-top: 1em;
      color: #8b8b8b;
      font-size: 3.6vw;
      @media screen and (orientation: landscape) {
        font-size: 2vw;
      }
    }

    &--avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 0.5em;

      border-radius: 50%;

      background-color: black;

      width: 7vh;
      height: 7vh;
      @media (orientation: landscape) {
        width: 11vh;
        height: 11vh;
      }
      svg {
        $size: 5vh;
        width: $size;
        height: $size;

        @media (orientation: landscape) {
          $size: 8vh;
          width: $size;
          height: $size;
        }
      }
    }

    &-username {
      width: 100%;
      padding-bottom: 2rem;
      font-size: 3vw;
      @media (orientation: landscape) {
        font-size: 1.9vw;
      }
    }

    &-description {
      flex-grow: 1;

      display: flex;
      align-items: center;
      justify-content: center;

      overflow: hidden;

      &__text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        font-size: 4vw;
        overflow: hidden;
        -webkit-line-clamp: 8;
        text-overflow: ellipsis;

        @media (orientation: landscape) {
          font-size: 2vw;
        }
      }
    }

    &-portraitCaption {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 4rem;
    }
  }
}
