

.Config {
  table td, table th {
    font-size: 1.5rem;
  }

  tr {
    cursor: pointer;
  }

  tr:hover th:first {
    background-color: rgba(0,0,0,.05);
  }

  tr a.btn, a.btn {
    font-size: 1.5rem;
  }
}
