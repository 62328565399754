.NotFoundPage{
  display: flex;
  height: 100%;
  background: #fff;
  margin-left: -4rem;
  align-items: center;
  justify-content: center;
  padding: 4rem;

  .img{
    display: block;
    margin: 0 0 -4rem -4rem;
  }
  .title{
    font-size: 4.8rem;
    margin-bottom: 2rem;
  }
  .frame{
    width: 65rem;
    max-width: 100%;
    text-align: center;
  }
  .desc{
    margin-bottom: 5rem;
    font-size: 1.8rem;
    line-height: 1.4;
  }
}